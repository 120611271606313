.tiLabel {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    cursor: pointer;
}

.tiInput {
    width: 100%;
    padding: 10px 5px;
    margin: 10px 0 20px 0;
    border: 1px solid black;
    box-sizing: border-box;
    position: relative;
    background-color: white;
    text-align: center;
}

.drop {
    width: 100%;
    padding: 10px 5px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    transition: .4s;
    border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.toggle {
    position: absolute;
    top: 10px;
    right: 10px;
}

@media (hover:hover) {
    .drop:hover {
        background-color: rgb(240, 240, 240);
    }
}