body {
  text-align: center;
  font-size: 13px;
  color: #20365f;
  padding: 30px 0 30px 0;
  margin: 0;
  background-image: url("./images/background-image.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: "Playfair Display", serif;
}

h1 {
  font-size: 34px;
  margin-top: 0;
}

h2 {
  font-size: 28px;
}

hr {
  margin: 30px 0 20px 0;
}

label {
  margin: 10px 0 0 0;
}

.reg-border {
  width: 30%;
  min-width: 380px;
  max-width: 450px;
  margin: auto;
  border: 3px solid #20365f;
  border-radius: 10px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.6);
}

form {
  text-align: left;
}

form input {
  width: 100%;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #20365f;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
}

form > input[type="submit"] {
  cursor: pointer;
}

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc(100% - 10px);
  margin: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px 0 10px 10px;
  border-radius: 5px;
}

button {
  margin: auto;
  margin: 30px 0 30px 0;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #248ec2;
  font-size: 16px;
  color: white;
  cursor: pointer;
  transition: 0.2s;
}

@media (hover: hover) {
  button:hover {
    background-color: white;
    color: #20365f;
  }
}

.required-label {
  color: rgb(131, 11, 11);
  font-size: 11px;
  float: right;
  margin-right: 20px;
}

.address-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.address-part {
  width: 85%;
}

.radio-container {
  text-align: center;
  margin: 20px 0 20px 0;
}

.radios {
  width: 20%;
  margin: auto;
}

.nav-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.back-button {
  width: 40%;
}

.next-button {
  width: 40%;
}

.privacy-statement {
  border: 1px solid #248ec2;
  padding: 0 10px 30px 10px;
  border-radius: 10px;
}

.pm-margin {
  margin-top: 20px;
}

.pm-checkbox {
  width: 20%;
  margin: 20px 0 20px 0;
}

.loading {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.849);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-size: 40px;
  padding: 20px;
  border-radius: 10px;
  animation-name: loadAnimation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
}

@keyframes loadAnimation {
  0% {
    background-color: none;
    color: #20365f;
  }
  50% {
    background-color: #248ec2;
    color: white;
  }
  100% {
    background-color: none;
    color: #20365f;
  }
}

/**
*Response queries
**/

@media only screen and (max-width: 500px) {
  .reg-border {
    width: 80%;
    min-width: 150px;
  }
}

.fade-in {
  animation: fadeIn ease 0.8s;
  -webkit-animation: fadeIn ease 0.8s;
  -moz-animation: fadeIn ease 0.8s;
  -o-animation: fadeIn ease 0.8s;
  -ms-animation: fadeIn ease 0.8s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
